<template>
  <div id="stampa">
    <el-row class="titolo" :span="24">
      <h3>STAMPA</h3>
    </el-row>
  </div>
</template>

<script>
  import Component from "vue-class-component";
  import Vue from "vue";

  @Component({})
  class Stampa extends Vue {}

  export default Stampa;
</script>

<style>
</style>